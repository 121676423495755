<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="footer__contact-wrapper">
        <div class="footer__contact">
          <p class="footer__contact-title main__title">
            Связаться с нами
          </p>
          <p class="footer__contact-text main__text">
            Оставьте заявку и наш менеджер Вам перезвонит
          </p>
          <button
            class="btn"
            @click="openModal()"
          >
            Связаться с нами
          </button>
        </div>
      </div>
      <div class="footer__info-wrapper">
        <div class="footer__info">
          <div class="footer__info-item">
            <img
              src="@/assets/images/adress.png"
              alt=""
              class="footer__info-img"
            >
            <a
              href="https://goo.gl/maps/SAxMeAphJEKriKi38"
              class="footer__info-text main__title"
            >м. Запоріжжя, вул. Привокзальна, 7-А</a>
          </div>
          <div class="footer__info-item">
            <img
              src="@/assets/images/email.svg"
              alt=""
              class="footer__info-img"
            >
            <a
              href="mailto:info@tez.zp.ua"
              class="footer__info-text main__title"
            >info@tez.zp.ua</a>
          </div>
          <div class="footer__info-item">
            <img
              src="@/assets/images/phone.png"
              alt=""
              class="footer__info-img"
            >
            <a
              href="tel:+380681899783"
              class="footer__info-text main__title"
            >+38 (068) 18 99 783</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'HomeFooterContact',
  methods: {
  openModal(){
    document.querySelector('.popUp').classList.add('popUp__active')
    document.querySelector('body').classList.add('lock')
  }
}
}
</script>
